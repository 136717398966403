import React from 'react'

import Layout from '../../components/Layout'
import PressRoll from '../../components/PressRoll'

export default class PressIndexPage extends React.Component {
  render() {
    return (
      <Layout>

        <section className="page-header">
          <div className="container">
            <div className="content subheaderlinks">
              <h1 className="breadcrumbs-links">
                Press
              </h1>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="content">
              <PressRoll />
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}
